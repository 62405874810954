// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/vercel/path0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guidelines-js": () => import("/vercel/path0/src/pages/guidelines.js" /* webpackChunkName: "component---src-pages-guidelines-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tattoo-aftercare-js": () => import("/vercel/path0/src/pages/tattoo-aftercare.js" /* webpackChunkName: "component---src-pages-tattoo-aftercare-js" */)
}

